import {useContext} from "react";
import {AppContext} from "../../../context/SuitBuilderContext";

export default function InfoSidebar() {

    const {
        infoSidebar,
        setInfoSidebar,
        infoSidebarData,
        setInfoSidebarData
    } = useContext(AppContext);

    console.log("Info sidebar Logs")
    console.log(infoSidebarData)

    return (
        <>
            <div className={`Info-sidebar ${infoSidebar && "active"}`}>
                <div className="text-start p-2  ">
                    <button className={"bg-transparent me-auto rounded-pill border crossBtn d-flex align-items-center justify-content-center"} onClick={()=>{
                        setInfoSidebar(false)
                        setInfoSidebarData(null)
                    }}><i className={"fa fa-times"}></i></button>
                </div>

                <div>
                    <div className="p-5">
                        <div className={"text-center"}>
                            <span className={"d-block h5"}>Lilac Wool Silk Linen</span>
                            <span className={"d-block"}>€748EUR</span>
                        </div>
                        <div className="pt-4">
                            <div className="d-flex align-items-center mb-3 justify-content-between small">
                                <span className={"fw-bold"}>Composition</span>
                                <span className={"text-secondary"}>70% Wool, 15% Silk, 15% Linen</span>
                            </div>
                            <div className="d-flex align-items-center mb-3 justify-content-between small">
                                <span className={"fw-bold"}>Weight</span>
                                <span className={"text-secondary"}>230gr/m2</span>
                            </div>
                            <div className="d-flex align-items-center mb-3 justify-content-between small">
                                <span className={"fw-bold"}>Season</span>
                                <span className={"text-secondary"}>Spring/Summer</span>
                            </div>
                            <div className="d-flex align-items-center mb-3 justify-content-between small">
                                <span className={"fw-bold"}>Woven by</span>
                                <span className={"text-secondary"}>E.Thomas (It)</span>
                            </div>
                            <div className="d-flex align-items-center mb-3 justify-content-between small">
                                <span className={"fw-bold"}>Fabric code</span>
                                <span className={"text-secondary"}>711302/3</span>
                            </div>
                        </div>
                    </div>










                </div>
            </div>
        </>
    )
}