import {useContext, useState} from "react";
import {SelectionContext} from "../../../context/UserSelectionContext";
import configg from "../../../config";
import infoImage from "../../../assets/information.png"
import {AppContext} from "../../../context/SuitBuilderContext";



export default function Tab1() {
    const [selected, setSelected] = useState(0)
    const {selectionData, setSelectedFabric} = useContext(SelectionContext);
    const {
        infoSidebar,
        setInfoSidebar,
        infoSidebarData,
        setInfoSidebarData
    } = useContext(AppContext);
    function handleInfoClick(data){
        console.log("Info cliked")
        console.log(data)
        setInfoSidebar(true)
        setInfoSidebarData(data)
    }
    return (
        <>
            <div className={"container-fluid fabric-tab tab"}>
                <div className="py-4 fw-light text-center h5 tab-heading">Select Your Fabric</div>
                <div className="fabrics-selector tab-column">
                    {selectionData && selectionData.fabrics.map((item, key) => {
                        return (
                            <div className={`fabric-card ${selected === key ? "selected" : null}`}
                                 key={key}
                                 onClick={() => {
                                     setSelected(key)
                                     setSelectedFabric(item)
                                 }}
                            >
                                <div className="fabric-image mb-1 position-relative">
                                    <img src={configg.img+item.cover_image} alt="no-image"/>
                                    <div className="position-absolute top-0 end-0 m-2 infoIcon"
                                         onClick={()=>handleInfoClick(item)}>
                                        <img src={infoImage} alt="no-image"/>
                                    </div>
                                </div>
                                <div className="details d-flex align-items-center justify-content-between">
                                    <span className={"fw-lighter"}>{item.title}</span>
                                    <span className={"fw-lighter"}>{item.currency} {item.price} </span>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
